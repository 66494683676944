<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import axios from "axios";
import {dateUtil} from "@/helpers/date-util";
import Swal from "sweetalert2";
import {errorCatcher} from "@/helpers/error-catcher";
import {required} from "vuelidate/lib/validators";
import allCountries from "@/helpers/all-countries";
import parsePhoneNumber from "libphonenumber-js";
import CreateOrderModal from "@/components/order/create-order-modal.vue";
import {marketplaceOrderHelper} from "../../../helpers/marketplace-order-helper";
import Switches from "vue-switches";

export default {
  components: {
    Layout,
    PageHeader,
    CreateOrderModal,
    Switches
  },

  computed: {
    marketplaceOrderHelper() {
      return marketplaceOrderHelper
    },

    dateUtil() {
      return dateUtil
    }
  },

  data() {
    return {

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: "",
        filterOn: [],
        sortBy: "name",
        dbSortBy: "",
        sortDesc: false,
        inputPage: "",
        items: []
      },

      submitted: false,

      modals: {
        editUser: {
          visibleModal: false,

          form: {
            id: "",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            dialCode: "",
            phoneNumber: "",
            roleId: ""
          }
        },

        changeReferralUser: {
          visibleModal: false,
          form: {
            userId: "",
            userReferralId: ""
          }
        }
      },

      multiStoreLimits: null,
      marketplaceOrders: null,
      productVariationsIds: null,
      roles: new Map(),

      filtering: {
        roleId: ""
      }

      // orderByMap: new Map([
      //   ["createdAt", "u.created_at"]
      // ])
    };
  },

  validations: {
    modals: {
      editUser: {
        form: {
          dialCode: {required},
          phoneNumber: {required}
        }
      },

      changeReferralUser: {
        form: {
          userId: {required},
          userReferralId: {
            required,
            isUUID: function (value) {
              if (!value) {
                return true
              }

              return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(value)
            }
          }
        }
      }
    }
  },

  methods: {

    openCreateOrderModal(user, type) {
      this.$refs.createOrderModal.openModal(() => this.$refs.table.refresh(), type, user.id)
    },

    getAllCountries() {
      return allCountries;
    },

    isPossibleNumber() {
      if (!this.modals.editUser.form.dialCode || !this.modals.editUser.form.phoneNumber) {
        return false
      }

      const phoneNumber = parsePhoneNumber(this.modals.editUser.form.dialCode + this.modals.editUser.form.phoneNumber)
      if (!phoneNumber) {
        return false
      }

      return phoneNumber.isValid()
    },

    getFields() {
      return [
        {key: "email", label: 'Email'},
        {key: "firstName", label: this.$t('message.first-name')},
        {key: "lastName", label: this.$t('message.last-name')},
        {key: "phoneNumber", slot: true, label: this.$t('message.phone-number')},
        {key: "role", slot: true, label: "Rola"},
        {key: "multiStore", slot: true, label: "MultiStore"},
        {key: "createdAt", dbKey: "u.created_at", sortable: true, label: this.$t('users.table.created-at'), formatter: value => dateUtil.asDateTime(value)},
        {key: "action", slot: true, label: this.$t('table.actions')}
      ]
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('users.title'),
          active: true
        }
      ]
    },

    async loadUsers() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        let orderBy = {}
        if (this.table.dbSortBy) {
          orderBy = JSON.stringify({
            [this.table.dbSortBy]: this.table.sortDesc ? "DESC" : "ASC"
          })
        }

        const {data} = await axios.get(`/user/list/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            page: page,
            size: this.table.perPage,
            filter: this.table.filter,
            orderBy: orderBy,
            ...this.filtering
          }
        });

        this.table.items = data
        await this.loadUsersCount()
        await this.loadMultiStoreLimits()
      } catch (error) {
        this.table.items = []
      }

      return this.table.items
    },

    async loadUsersCount() {
      try {
        const {data} = await axios.get(`/user/count`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            filter: this.table.filter,
            ...this.filtering
          }
        });

        this.table.totalRows = data
        this.table.rows = data
      } catch (error) {
        this.table.totalRows = 0;
        this.table.rows = 0;
      }
    },

    sendForgotPassword() {
      const json = JSON.stringify({
        "email": this.modals.editUser.form.email,
      });

      axios.post(`/user/forgot-password`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        this.hideEditUserModal()
        Swal.fire("Sukces!", "Na adres E-mail użytkownika został wysłany link do zmiany hasła!", "success");
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    editUser() {
      this.submitted = true;

      this.$v.modals.editUser.form.$touch();
      if (this.$v.modals.editUser.form.$invalid) {
        return;
      }

      if (!this.isPossibleNumber()) {
        return
      }

      const json = JSON.stringify({
        user: {
          id: this.modals.editUser.form.id,
          firstName: this.modals.editUser.form.firstName,
          lastName: this.modals.editUser.form.lastName,
          email: this.modals.editUser.form.email,
          password: this.modals.editUser.form.password,
          phoneNumber: this.modals.editUser.form.phoneNumber,
          dialCode: this.modals.editUser.form.dialCode,
          roleId: this.modals.editUser.form.roleId,
          allowedOnBetaEnvironment: this.modals.editUser.form.allowedOnBetaEnvironment
        }
      });

      axios.post(`/user/update`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        this.submitted = false;

        this.$bvToast.toast("Pomyślnie zaktualizowano konto użytkownika", {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.$refs.table.refresh()
        this.hideEditUserModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    openEditUserModal(user) {
      this.modals.editUser.form = Object.assign({}, user)
      this.modals.editUser.visibleModal = true
    },

    hideEditUserModal() {
      this.modals.editUser.visibleModal = false
      this.modals.editUser.form = {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        dialCode: "",
        phoneNumber: "",
        roleId: "",
        allowedOnBetaEnvironment: false
      }
    },

    openChangeUserReferralModal(user) {
      this.modals.changeReferralUser.form.email = user.email
      this.modals.changeReferralUser.form.userId = user.id
      this.modals.changeReferralUser.form.userReferralId = user.referralUserId
      this.modals.changeReferralUser.visibleModal = true
    },

    hideChangeUserReferralModal() {
      this.modals.changeReferralUser.visibleModal = false
      this.modals.changeReferralUser.form = {
        userId: "",
        userReferralId: "",
        email: ""
      }
    },

    changeUserReferral() {
      this.submitted = true

      this.$v.modals.changeReferralUser.form.$touch();
      if (this.$v.modals.changeReferralUser.form.$invalid) {
        return;
      }

      const json = JSON.stringify({
        "userId": this.modals.changeReferralUser.form.userId,
        "userReferralId": this.modals.changeReferralUser.form.userReferralId,
      });

      axios.post(`/user/update-referral-id`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        this.submitted = false
        this.hideChangeUserReferralModal()
        Swal.fire("Sukces!", "Zmieniono kod polecającego!", "success");
      }).catch((error) => {
        this.submitted = false
        errorCatcher.catchErrors(error);
      })
    },

    getUser(id) {
      if (!this.table.items) {
        return {}
      }

      for (const user of this.table.items) {
        if (user.id === id) {
          return user
        }
      }

      return {}
    },

    async simulateUser(user) {
      try {
        await this.createSession(user.id)
      } catch (error) {
        return
      }

      try {
        await this.switchSession(this.$store.getters["simulatesession/getNewSession"])
      } catch (error) {
        return
      }

      await this.$store.dispatch("shop/setSelectedShopId", "")
      await this.$store.dispatch("market/setSelectedMarketId", "")
      await this.$router.push('/dashboard/home')
    },

    async createSession(userId) {
      try {
        const json = JSON.stringify({
          userId: userId
        });

        const {data} = await axios.post(`/user/simulation/create-session`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        await this.$store.dispatch("simulatesession/setSessions", data)
      } catch (error) {
        errorCatcher.catchErrors(error)
        throw error
      }
    },

    async switchSession(session) {
      try {
        const json = JSON.stringify(session);
        await axios.post(`/user/simulation/change-session`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });
      } catch (error) {
        errorCatcher.catchErrors(error)
        throw error
      }
    },

    async loadMultiStoreLimits() {
      if (this.table.items.length === 0) {
        return;
      }

      const marketplaceOrderIds = [];
      for (const user of this.table.items) {
        if (user.shopPlanMarketplaceOrderId) {
          marketplaceOrderIds.push(user.shopPlanMarketplaceOrderId);
        }
      }

      if (marketplaceOrderIds.length === 0) {
        return
      }

      try {
        const {data} = await axios.get(`/stripe/subscription/orders`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            marketplaceOrderIds: marketplaceOrderIds.join(",")
          }
        });

        this.multiStoreLimits = new Map(data.map((obj) => [obj.marketplaceOrderId, obj]));
        await this.loadMarketplaceOrders()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadMarketplaceOrders() {
      if (this.table.items.length === 0) {
        return;
      }

      const marketplaceOrderIds = [];
      for (const user of this.table.items) {
        if (user.shopPlanMarketplaceOrderId) {
          marketplaceOrderIds.push(user.shopPlanMarketplaceOrderId);
        }
      }

      if (marketplaceOrderIds.length === 0) {
        return
      }

      try {
        const {data} = await axios.get(`/marketplace/order/product/fetch`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            marketplaceOrderIds: marketplaceOrderIds.join(",")
          }
        });

        this.marketplaceOrders = new Map(data.map((obj) => [obj.marketplaceOrderId, obj]));
        await this.loadMarketplaceProductVariations()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadMarketplaceProductVariations() {
      if (!this.marketplaceOrders || this.marketplaceOrders.length === 0) {
        return;
      }

      const productVariationsIds = [];

      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of this.marketplaceOrders) {
        productVariationsIds.push(value.marketplaceProductVariationId);
      }

      if (productVariationsIds.length === 0) {
        return
      }

      try {
        const {data} = await axios.get(`/marketplace/product/variation/fetch`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            ids: productVariationsIds.join(",")
          }
        });

        this.productVariations = new Map(data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getProductVariation(user) {
      if (!this.marketplaceOrders || !this.productVariations || !user.shopPlanMarketplaceOrderId) {
        return
      }

      const marketPlaceOrder = this.marketplaceOrders.get(user.shopPlanMarketplaceOrderId)
      if (!marketPlaceOrder) {
        return null
      }

      return this.productVariations.get(marketPlaceOrder.marketplaceProductVariationId) || null
    },

    getMultiStoreLimit(user) {
      if (!this.multiStoreLimits || !user.shopPlanMarketplaceOrderId) {
        return null
      }

      return this.multiStoreLimits.get(user.shopPlanMarketplaceOrderId) || null;
    },

    async loadRoles() {
      try {
        const { data } = await axios.get(`/role`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.roles = new Map(data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getRole(roleId) {
      if (!this.roles) {
        return null
      }

      return this.roles.get(roleId) || null
    },

    getRoleName(roleId) {
      return this.getRole(roleId).name || ''
    },

    changeFilteringRole(roleId) {
      this.filtering.roleId = roleId
      this.$refs.table.refresh()
    },

  },

  async created() {
    await this.loadRoles()
  }

};
</script>

<template>
  <Layout>
    <PageHeader :items="getItems()" :title="$t('users.title')"/>
    <create-order-modal ref="createOrderModal"/>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <custom-card-subtitle title="Filtrowanie"/>

              <div class="col-12 col-lg-2">
                <ecat-multiselect
                    label="Rola"
                    save-id="id"
                    view-id="name"
                    placeholder="Wszystkie role"
                    load-url="/role"
                    :can-unselect="true"
                    @change="value => changeFilteringRole(value)"
                />
              </div>
            </div>

            <ecat-table
                ref="table"
                :fields="getFields()"
                :items="loadUsers"
                :pagination-top="true"
                :table="table">
              <template v-slot:phoneNumber="{ item }">
                <span> {{ item.dialCode }} {{ item.phoneNumber }}</span>
              </template>

              <template v-slot:role="{ item }">
                <span>{{ getRoleName(item.roleId) }}</span>
              </template>

              <template v-slot:multiStore="{ item }">
                <span v-if="getMultiStoreLimit(item) && getMultiStoreLimit(item).internalStatus === 'ACTIVE'">
                  <template v-if="getProductVariation(item)">
                    {{ getProductVariation(item).title }} -
                  </template>

                  {{ marketplaceOrderHelper.getExpireTime(getMultiStoreLimit(item)) }}
                </span>
                <span v-else>
                  -
                </span>
              </template>

              <template v-slot:action="{ item }">
                <div class="button-items">
                  <a id="user-info" class="clickable-element text-primary mr-3" @click="openEditUserModal(item)"><i
                      class="mdi mdi-pencil m font-size-18"></i></a>
                  <b-tooltip placement="left" target="user-info">{{ $t('users.edit') }}</b-tooltip>

                  <a :id="`change-user-referral-${item.id}`" class="clickable-element text-warning mr-3"
                     @click="openChangeUserReferralModal(item)"><i class="mdi mdi-trophy-award font-size-18"></i></a>
                  <b-tooltip :target="`change-user-referral-${item.id}`" placement="left">Zmiana kodu polecającego
                  </b-tooltip>

                  <a :id="`user-simulation-${item.id}`" class="clickable-element text-success mr-3"
                     @click="simulateUser(item)"><i class="mdi mdi-account-voice font-size-18"></i></a>
                  <b-tooltip :target="`user-simulation-${item.id}`" placement="left">{{ $t('users.simulation') }}
                  </b-tooltip>

                  <a :id="`open-create-order-multistore-${item.id}`" class="clickable-element text-info mr-3"
                     @click="openCreateOrderModal(item, 'STORE_LIMIT')"><i class="mdi mdi-shopping font-size-18"></i></a>
                  <b-tooltip :target="`open-create-order-multistore-${item.id}`" placement="left">MultiStore</b-tooltip>
                </div>
              </template>
            </ecat-table>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        v-model="modals.editUser.visibleModal"
        :title="`Edycja konta użytkownika: ${modals.editUser.form.id ? modals.editUser.form.email : ''}`"
        hide-footer
        title-class="font-18"
        @esc="hideEditUserModal"
        @hide="hideEditUserModal">
      <form>
        <div class="form-group">
          <label for="firstName">{{ $t('message.first-name') }}</label>
          <input id="firstName" v-model="modals.editUser.form.firstName" class="form-control" type="text"/>
        </div>

        <div class="form-group">
          <label for="lastName">{{ $t('message.last-name') }}</label>
          <input id="lastName" v-model="modals.editUser.form.lastName" class="form-control" type="text"/>
        </div>

        <div class="form-group">
          <label for="email">E-mail</label>
          <input id="email" v-model="modals.editUser.form.email" class="form-control" type="email"/>
        </div>

        <div class="form-group">
          <label for="password">{{ $t('message.password') }}</label>
          <input id="password" v-model="modals.editUser.form.password" class="form-control" type="password"/>
        </div>

        <div class="form-group" v-if="$store.getters['role/hasPermission']('ROLE_USER_SWITCH')">
          <ecat-multiselect
              label="Rola"
              load-url="/role/pagination"
              param="name"
              placeholder="Wybierz rolę"
              query-url="/role/by-name"
              save-id="id"
              view-id="name"
              @change="value => modals.editUser.form.roleId = value"
          />
        </div>

        <div class="form-group">
          <div class="d-flex flex-sm-column mx-2">
            <label class="font-size-12">
              Czy użytkownik ma posiadać dostęp do wersji beta:
              {{ modals.editUser.form.allowedOnBetaEnvironment ? "Tak" : "Nie" }}
            </label>
            <switches v-model="modals.editUser.form.allowedOnBetaEnvironment" class="my-0" color="primary"/>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>{{ $t('message.country') }}</label>
                <select v-model="modals.editUser.form.dialCode" :class="{ 'is-invalid': submitted && $v.modals.editUser.form.dialCode.$error }"
                        class="custom-select">
                  <option v-for="(item, index) in getAllCountries()" :key="index" :value="`${item.dialCode}`">
                    {{ item.name }} ({{ item.dialCode }})
                  </option>
                </select>

                <div v-if="submitted && $v.modals.editUser.form.dialCode.$error" class="invalid-feedback">
                  <span v-if="!$v.modals.editUser.form.dialCode.required">{{ $t('message.required') }}</span>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>{{ $t('message.phone-number') }}</label>
                <input
                    id="phoneNumber"
                    v-model="modals.editUser.form.phoneNumber"
                    :class="{ 'is-invalid': (submitted && $v.modals.editUser.form.phoneNumber.$error) || !isPossibleNumber()}"
                    class="form-control"
                    type="text"/>
                <div v-if="submitted && $v.modals.editUser.form.phoneNumber.$error" class="invalid-feedback">
                  <span v-if="!$v.modals.editUser.form.phoneNumber.required">{{ $t('message.required') }}</span>
                </div>

                <div v-if="!isPossibleNumber()" class="invalid-feedback">{{ $t('message.number-error') }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-right">
          <b-button variant="info" @click="sendForgotPassword">Wyślij link do zresetowania hasła</b-button>

          <b-button class="ml-1" variant="success" @click="editUser">{{ $t('message.save') }}</b-button>
          <b-button class="ml-1" variant="danger" @click="hideEditUserModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

    <b-modal
        id="modal-2"
        v-model="modals.changeReferralUser.visibleModal"
        :title="`Zmiana kodu polecającego użytkownika: ${modals.changeReferralUser.form.userId ? modals.changeReferralUser.form.email : ''}`"
        hide-footer
        title-class="font-18"
        @esc="hideChangeUserReferralModal"
        @hide="hideChangeUserReferralModal">
      <form>
        <div class="form-group">
          <ecat-multiselect
              :class="{ 'is-invalid': submitted && $v.modals.changeReferralUser.form.userReferralId.$error }"
              :set-value="modals.changeReferralUser.form.userReferralId"
              :show-labels="false"
              fetch-one-url="/user"
              label="Nowy kod polecającego:"
              load-url="/user/list/pagination"
              param="email"
              placeholder="Wybierz użytkownika"
              query-url="/user/by-email"
              save-id="id"
              view-id="email"
              @change="value => modals.changeReferralUser.form.userReferralId = value"
          />

          <div v-if="!$v.modals.changeReferralUser.form.userReferralId.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="text-right">
          <b-button class="ml-1" variant="success" @click="changeUserReferral">{{ $t('message.save') }}</b-button>
          <b-button class="ml-1" variant="danger" @click="hideChangeUserReferralModal">{{
              $t('message.cancel')
            }}
          </b-button>
        </div>
      </form>
    </b-modal>

  </Layout>
</template>